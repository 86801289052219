import {defer} from '@shopify/remix-oxygen';
import {Suspense, useEffect, useState} from 'react';
import {Await, useLoaderData} from '@remix-run/react';
import {ProductSwimlane, FeaturedCollections, Hero} from '~/components';
import {MEDIA_FRAGMENT, PRODUCT_CARD_FRAGMENT} from '~/data/fragments';
import {getHeroPlaceholder} from '~/lib/placeholders';
import {AnalyticsPageType} from '@shopify/hydrogen';

export async function loader({params, context}) {
  const {language, country} = context.storefront.i18n;

  if (
    params.lang &&
    params.lang.toLowerCase() !== `${language}-${country}`.toLowerCase()
  ) {
    // If the lang URL param is defined, yet we still are on `EN-US`
    // the the lang param must be invalid, send to the 404 page
    throw new Response(null, {status: 404});
  }

  const {metaobjects} = await context.storefront.query(TEST_QUERY);
  const metaobjectFields = metaobjects.nodes[0].fields
  const {mainBanner, mainBannerMobile, collection1} = metaobjects.nodes[0].fields

  const shop = await context.storefront.query(HOMEPAGE_SEO_QUERY);

  let hero = {
    spread: {},
    desktopImage: {}
  }
  hero.spread.reference = metaobjectFields.find(x => x.key == 'main_banner_mobile').reference;
  hero.desktopImage.reference = metaobjectFields.find(x => x.key == 'main_banner').reference;


  return defer({
    shop,
    metaobjects,
    // metaobjectFields,
    primaryHero: hero,
    secondaryHero: context.storefront.query(COLLECTION_QUERY, {
      variables: {
        id: metaobjectFields.find(x => x.key == "collection_1").value,
        country,
        language,
      },
    }),
    tertiaryHero: context.storefront.query(COLLECTION_QUERY, {
      variables: {
        id: metaobjectFields.find(x => x.key == "collection_2").value,
        country,
        language,
      },
    }),
    theStoryHero: context.storefront.query(PAGE_QUERY, {
      variables: {
        handle: "the-story",
        country,
        language,
      },
    }),
    contactHero: context.storefront.query(PAGE_QUERY, {
      variables: {
        handle: "contact",
        country,
        language,
      },
    }),
    // These different queries are separated to illustrate how 3rd party content
    // fetching can be optimized for both above and below the fold.
    // featuredProducts: context.storefront.query(
    //   HOMEPAGE_FEATURED_PRODUCTS_QUERY,
    //   {
    //     variables: {
    //       /**
    //        * Country and language properties are automatically injected
    //        * into all queries. Passing them is unnecessary unless you
    //        * want to override them from the following default:
    //        */
    //       country,
    //       language,
    //     },
    //   },
    // ),
    // secondaryHero: context.storefront.query(COLLECTION_HERO_QUERY, {
    //   variables: {
    //     handle: 'backcountry',
    //     country,
    //     language,
    //   },
    // }),
    featuredCollections: context.storefront.query(FEATURED_COLLECTIONS_QUERY, {
      variables: {
        country,
        language,
      },
    }),
    // tertiaryHero: context.storefront.query(COLLECTION_HERO_QUERY, {
    //   variables: {
    //     handle: 'winter-2022',
    //     country,
    //     language,
    //   },
    // }),
    // analytics: {
    //   pageType: AnalyticsPageType.home,
    // },
  });
}

export default function Homepage() {
  const {
    metaobjects,
    primaryHero,
    secondaryHero,
    tertiaryHero,
    theStoryHero,
    contactHero
  } = useLoaderData();

  // TODO: skeletons vs placeholders
  const skeletons = getHeroPlaceholder([{}, {}, {}]);

  // TODO: analytics
  // useServerAnalytics({
  //   shopify: {
  //     pageType: ShopifyAnalyticsConstants.pageType.home,
  //   },
  // });

  const [isMounted, setIsMounted] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  const [width, setWidth] = useState(769);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  useEffect(() => {
		if (!isMounted && isMobile) {
      setTimeout(() => {
        window.scrollTo({ top: 300, behavior: 'smooth' });
        setIsScrolled(true)
      }, 500)
      setIsMounted(true)
    }
	}, [isMounted, isMobile]);

  useEffect(() => {
		if (isMounted && isScrolled) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 400)
    }
	}, [isMounted, isScrolled]);


  return (
    <>
      {primaryHero && (
        <Hero {...primaryHero} height="full" top loading="eager" />
      )}

      <div className='grid grid-cols-1 md:grid-cols-2 h-full'>
        {secondaryHero && (
          <Suspense fallback={<Hero {...skeletons[1]} />}>
            <Await resolve={secondaryHero}>
              {({hero}) => {
                if (!hero) return <></>;
                return <Hero {...hero} width="1/2" height="full" />;
              }}
            </Await>
          </Suspense>
        )}
        {tertiaryHero && (
          <Suspense fallback={<Hero {...skeletons[1]} />}>
            <Await resolve={tertiaryHero}>
              {({hero}) => {
                if (!hero) return <></>;
                return <Hero {...hero} width="1/2" height="full" />;
              }}
            </Await>
          </Suspense>
        )}
        {theStoryHero && (
          <Suspense fallback={<Hero {...skeletons[1]} />}>
            <Await resolve={theStoryHero}>
              {({hero}) => {
                if (!hero) return <></>;
                return <Hero {...hero} width="1/2" height="full" page />;
              }}
            </Await>
          </Suspense>
        )}
        {contactHero && (
          <Suspense fallback={<Hero {...skeletons[1]} />}>
            <Await resolve={contactHero}>
              {({hero}) => {
                if (!hero) return <></>;
                return <Hero {...hero} width="1/2" height="full" page />;
              }}
            </Await>
          </Suspense>
        )}
      </div>


      {/* {featuredProducts && (
        <Suspense>
          <Await resolve={featuredProducts}>
            {({products}) => {
              if (!products?.nodes) return <></>;
              return (
                <ProductSwimlane
                  products={products.nodes}
                  title="Featured Products"
                  count={4}
                />
              );
            }}
          </Await>
        </Suspense>
      )} */}



      {/* {featuredCollections && (
        <Suspense>
          <Await resolve={featuredCollections}>
            {({collections}) => {
              if (!collections?.nodes) return <></>;
              return (
                <FeaturedCollections
                  collections={collections.nodes}
                  title="Collections"
                />
              );
            }}
          </Await>
        </Suspense>
      )} */}

      {/* {tertiaryHero && (
        <Suspense fallback={<Hero {...skeletons[2]} />}>
          <Await resolve={tertiaryHero}>
            {({hero}) => {
              if (!hero) return <></>;
              return <Hero {...hero} />;
            }}
          </Await>
        </Suspense>
      )} */}
    </>
  );
}

const COLLECTION_CONTENT_FRAGMENT = `#graphql
  ${MEDIA_FRAGMENT}
  fragment CollectionContent on Collection {
    id
    handle
    title
    descriptionHtml
    heading: metafield(namespace: "hero", key: "title") {
      value
    }
    byline: metafield(namespace: "hero", key: "byline") {
      value
    }
    cta: metafield(namespace: "hero", key: "cta") {
      value
    }
    desktopImage: metafield(namespace: "hero", key: "desktop_image") {
      reference {
        ...Media
      }
    }
    spread: metafield(namespace: "hero", key: "image") {
      reference {
        ...Media
      }
    }
    spreadSecondary: metafield(namespace: "hero", key: "secondaryimage") {
      reference {
        ...Media
      }
    }
  }
`;
const PAGE_CONTENT_FRAGMENT = `#graphql
  ${MEDIA_FRAGMENT}
  fragment PageContent on Page {
    id
    handle
    heading: metafield(namespace: "hero", key: "title") {
      value
    }
    spread: metafield(namespace: "hero", key: "image") {
      reference {
        ...Media
      }
    }
  }
`;

const TEST_QUERY = `#graphql
${MEDIA_FRAGMENT}
  query collectionContent( $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    metaobjects(type: "home_page_banners", first: 10) {
      nodes {
        fields {
          value
          type
          key
          reference {
            ...Media
          }
        }
        mainBanner: field(key: "main_banner") {
          reference {
            ...Media
          }
        }
        mainBannerMobile: field(key: "main_banner_mobile") {
          reference {
            ...Media
          }
        }
        collection1: field(key: "collection_1") {
          reference {
            __typename
            ... on Collection {
              id
              handle
              title
              descriptionHtml
              heading: metafield(namespace: "hero", key: "title") {
                value
              }
              desktopImage: metafield(namespace: "hero", key: "desktop_image") {
                reference {
                  ...Media
                }
              }
              spread: metafield(namespace: "hero", key: "image") {
                reference {
                  ...Media
                }
              }
            }
          }
        }
      }
    }
  }
`;
const HOMEPAGE_SEO_QUERY = `#graphql
  query collectionContent($country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    shop {
      name
      description
    }
  }
`;
const COLLECTION_QUERY = `#graphql
  ${COLLECTION_CONTENT_FRAGMENT}
  query collectionContent($id: ID, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    hero: collection(id: $id) {
      ...CollectionContent
    }
  }
`;
const PAGE_QUERY = `#graphql
  ${PAGE_CONTENT_FRAGMENT}
  query pageContent($handle: String, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    hero: page(handle: $handle) {
      ...PageContent
    }
  }
`;

const COLLECTION_HERO_QUERY = `#graphql
  ${COLLECTION_CONTENT_FRAGMENT}
  query collectionContent($handle: String, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    hero: collection(handle: $handle) {
      ...CollectionContent
    }
  }
`;

// @see: https://shopify.dev/api/storefront/latest/queries/products
export const HOMEPAGE_FEATURED_PRODUCTS_QUERY = `#graphql
  ${PRODUCT_CARD_FRAGMENT}
  query homepageFeaturedProducts($country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    products(first: 8) {
      nodes {
        ...ProductCard
      }
    }
  }
`;

// @see: https://shopify.dev/api/storefront/latest/queries/collections
export const FEATURED_COLLECTIONS_QUERY = `#graphql
  query homepageFeaturedCollections($country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    collections(
      first: 4,
      sortKey: UPDATED_AT
    ) {
      nodes {
        id
        title
        handle
        image {
          altText
          width
          height
          url
        }
      }
    }
  }
`;
